





























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import CloseLine from '@/app/ui/assets/close_icon_line.vue'
import ModalConfirm from '../ModalConfirm/index.vue'
import ModalSuccess from '../ModalSuccess/index.vue'
import PayrollTextInput from '../../PayrollTextInput/index.vue'
import controller from '@/app/ui/controllers/ManageDayController'

interface IValidations {
  form: {
    minDaysAmount: {
      required: unknown
      minValue: unknown
      maxValue: unknown
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    Modal,
    Button,
    DropdownSelect,
    TextInput,
    CloseLine,
    ModalConfirm,
    ModalSuccess,
    PayrollTextInput
  },
})

export default class ModalEditManageDays extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: [] }) private monthList!: string[]
  @Prop() private id!: string
  @Prop() private selectedYear!: string
  @Prop() private selectedMonth!: string
  @Prop() private currentMinDays!: number

  controller = controller

  modalConfirmVisible = false
  modalSuccessVisible = false
  modalEditHidden = false

  form = {
    selectedMonth: this.monthNames[0],
    minDaysAmount: 0
  }

  created() {
    this.form.selectedMonth = this.monthNames[this.getMonthIdx]
    this.form.minDaysAmount = this.currentMinDays
  }

  get getMonthIdx(): number {
    return Number(this.selectedMonth) - 1
  }

  get monthNames(): Array<{ label: string, value: string }> {
    return this.monthList.map((month) => {
      return {
        label: month,
        value: month
      }
    })
  }

  get validateMinDaysAmount(): string {
    if (this.$v.form.minDaysAmount?.$invalid) {
      return 'Jumlah minimal hari harus diantara 1-31'
    }
    return ''
  }

  @Validations()
  validations(): IValidations {
    return {
      form: {
        minDaysAmount: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(99)
        }
      }
    }
  }

  private onCancelConfirm() {
    this.modalConfirmVisible = false
    this.modalEditHidden = false
  }

  private onConfirm() {
    this.modalConfirmVisible = true
    this.modalEditHidden = true
  }

  private onSubmit() {
    this.modalConfirmVisible = false
    this.modalEditHidden = false

    const editPayload = {
      id: Number(this.id),
      month: Number(this.selectedMonth),
      year: Number(this.selectedYear),
      total_minimal_day: Number(this.form.minDaysAmount)
    }

    controller.update(editPayload)
  }
}
