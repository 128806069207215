





























































import { Component, Vue, Watch } from 'vue-property-decorator'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import ModalEditManageDays from '../components/Modals/ModalEditManageDays/index.vue'
import controller from '@/app/ui/controllers/ManageDayController'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import { ManageDay, ManageDayYear } from '@/domain/entities/ManageDay'
import { Utils } from '@/app/infrastructures/misc'
import ModalSuccess from '../components/Modals/ModalSuccess/index.vue'

@Component({
  components: {
    DropdownSelect,
    DataTable,
    Button,
    ModalEditManageDays,
    ModalSuccess,
    Loading
  }
})

export default class ManageDaysPage extends Vue {
  controller = controller
  modalEditVisible = false
  modalSuccessVisible = false
  currentYear = dayjs().locale(dayjsID).format('YYYY')

  parameters = {
    year: this.currentYear
  }

  yearList: Array<{ label: number, value: number }> = []

  form = {
    id: '',
    selectedYear: { label: this.currentYear, value: this.currentYear },
    selectedMonth: '',
    selectedMinDay: 0
  }

  headers = [
    'Bulan',
    'Jumlah Min. Hari',
    'Action',
    'Created at',
    'Updated at',
    'Created by',
    'Updated by',
  ]

  monthName: Array<string> = []
  manageDayDataList: Array<Array<string | number | { [k: string]: number | boolean }>> = []

  created() {
    if (!Utils.isSuperAdmin() && !Utils.isFinance()) {
      this.$notify({
        title: 'Forbidden Access',
        text: 'Your Account is not permitted to request to some endpoints',
        type: 'error',
        duration: 5000,
      })

      this.$router.push('/')
    }
    this.monthName = new Array(12).fill('').map((val, idx) => dayjs().month(idx).locale(dayjsID).format('MMMM'))

    this.fetchManageDay()
  }

  get params(): Record<string, string> {
    return { year: this.parameters.year };
  }

  private fetchManageDay() {
    controller.getYearList()
    controller.getAll(this.params)
  }

  private onEdit(id: number, month: number, totalMinDay: number) {
    this.form.id = String(id)
    this.form.selectedMonth = String(month)
    this.form.selectedMinDay = totalMinDay
    this.modalEditVisible = true
  }

  private onModalEditClose() {
    this.form.selectedMonth = ''
    this.modalEditVisible = false
  }

  private setSelectedYear(year: { label: string, value: string }) {
    this.form.selectedYear = year
    this.parameters.year = String(year.value)
    this.fetchManageDay()
  }

  private formatDateTime(dateTime: string) {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(dateTime, 'DD MMM, HH:mm Z'),
    )
  }

  @Watch('controller.dataManageDayList')
  setDataManageDayList(data: ManageDay[]) {
    const list = data.map((item) => {
      return [
        item.month ? this.monthName[Number(item.month) - 1] : '',
        item.totalMinimalDay || '',
        {
          id: item.id || 0,
          month: Number(item.month) || 0,
          status: item.status || false ,
          total_min_day: item.totalMinimalDay || 0
        },
        item.createdAt ? this.formatDateTime(item.createdAt) : '',
        item.updatedAt ? this.formatDateTime(item.updatedAt) : '',
        item.createdBy || '',
        item.updatedBy || ''
      ]
    })

    this.manageDayDataList = list
  }

  @Watch('controller.dataManageDayYearList')
  setDataManageDayYearList(data: ManageDayYear[]) {
    let list: Array<{ label: number, value: number }> = []

    data.forEach((item) => {
      if (item.year) {
        list.push(
          { label: item.year, value: item.year }
        )
      }
    })

    this.yearList = list
  }

  @Watch('controller.isEditManageDaySuccess')
  setEditManageDaySuccess(data: boolean) {
    this.form.selectedMonth = ''
    if (data) {
      this.modalSuccessVisible = true
      this.fetchManageDay()

      this.controller.setIsEditManageDaySuccess(false)
    }
  }
}
